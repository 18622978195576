.selectAddressContainer {
    width: 80%;
    margin    : 50px auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 30px 70px;
}

.selectAddressheading {
    color      : #482611;
    font-size  : 26px;
    padding    : 30px 0;
    font-weight: bold;
}

.selectAddressLabel {
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 5px;
    color: #111111;
}

.selectAdderssSubmit {
    margin-left: 15px;
    margin-top: 30px;
}

.selectArrow {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-size: .6em;
    background-position: calc(100% - 1.3em) center;
    background-repeat: no-repeat;
}

.addressCheck {
    padding: 30px 0 10px 15px;
}

.addressCheckSpan {
    padding-left: 10px;
    font-weight: bold;
}

.addressHr {
    border: 1px solid #ced4da;
    margin: 20px 15px 0 15px;
}

.addressRow {
    margin: 0;
}

.addressBtn {
    width: 60%;
}

.address-menu {
    margin-bottom: 20px;
}


@media screen and (max-width: 767px) {
    .selectAddressContainer {
        padding: 20px 20px 20px 20px;
    }
}


/* Chrome, Safari, Edge, Opera */
.addressPhone::-webkit-outer-spin-button,
.addressPhone::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.addressPhone[type=number] {
  -moz-appearance: textfield;
}