.react-multi-carousel-list {
    padding-bottom: 10px;
}

.see-all {
    color: #007185 !important;
    PADDING: 10px;
}

.see-all:hover {
    text-decoration: underline !important;
    color: #c7511f !important;
}

.react-multiple-carousel__arrow--left {
    left: calc(1% + 1px);
}

.react-multiple-carousel__arrow--right {
    right: calc(1% + 1px);
}

.MuiCardMedia-root {
    min-height: 250px !important;
    background-size: contain !important;
}

.allCategTitle {
    margin: 0 !important;
    padding: 10px;
}

@media only screen and (min-width: 280px) and (max-width: 576px) {
    .see-all {
        padding-left: 10px;
    }
}