.ptb-10 {
    padding: 10px 0;
}

.main-row {
    text-align: left;
    justify-content: left;
}

.container {
    padding: 20px !important;
}

@media only screen and (min-width: 260px) and (max-width: 480px) {
    .hero-text {
        font-size: 18px !important;
    }
}

@media only screen and (max-width: 991px) {
    .empty-cart-container {
        flex-direction: column;
    }
    .main-row {
        text-align: center;
        justify-content: center;
        padding: 10px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .empty-cart-container {
        flex-direction: row;
    }
    .main-row {
        text-align: center;
        justify-content: center;
        padding: 10px;
    }
}