.ant-menu-submenu-title {
    border: 1px solid #f0f0f0;
}

.ant-menu-inline {
    border: none;
}

.upper-filter-container {
    display: none;
}

.top-sticky {
    position: sticky;
    top: 90px;
    z-index: 0;
}

.ant-divider-horizontal {
    margin: 10px 0 !important;
}

.pt-15 {
    padding-top: 15px;
}

.filterMobileView {
    display: none;
}

.productListContainer {
    max-width: 90%;
}

.ant-breadcrumb>span:last-child {
    color: inherit;
}

@media only screen and (max-width: 991px) {
    .upper-filter-container {
        z-index: 1;
        position: sticky;
        top: 53;
        display: block;
    }

    .filter-container {
        display: none;
    }

    /* .filterMobileView {
        display: block;
    } */
}

@media (max-width: 767px) {
    .filterMobileView {
        display: block;
    }
}