.logo-sec .ant-col{ margin: 15px 0px; max-width:19%; text-align: center; }
.logo-sec .ant-col img{ max-width:150px; width:100%; height:100px; object-fit: contain; padding:0px 15px; }

@media(max-width:1365px){
    .logo-sec .ant-col img{ height:60px; }
}
@media(max-width:1199px){
    .logo-sec .ant-col{ max-width:25%; flex:25%; text-align: center; }
}
@media(max-width:991px){
    .logo-sec .ant-col{ max-width:33.33%; flex:33.33%; text-align: center; } 
}
@media screen and (max-width: 767px) {
.logo-sec .ant-col{ max-width:33.33%; flex:33.33%; text-align: center; }
.logo-sec .ant-col img{ max-width: 140px; height:70px; }
}
@media(max-width:575px){
    .logo-sec .ant-col{ max-width: 50%; flex: 50%; }
}    
