.categorySliderContainer {
    max-width: 90%;
}

.prod-title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
    margin-bottom: 1.5rem; ;
}