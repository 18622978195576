.shoppingCartHeading {
  color: #482611;
  font-size: 26px;
  padding: 30px;
  font-weight: bold;
}

.addToCartContainer {
  width: 90%;
  margin: 50px auto;
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 20%), 0 0 25px 0 rgb(0 0 0 / 20%);
}

/* .addToCartCard {
    padding: 0 30px;
} */

.cartTopBarHeading {
  font-size: 15px;
  font-weight: bold;
}

.cartTopBarhr {
  border-bottom: 2px solid #afafaf;
  width: 100%;
  margin-top: 0 !important;
}

.cartStock {
  font-size: 14px;
  color: #00691b;
  font-style: italic;
}

.addGiftCheckbox {
  font-size: 14px;
}

.cartProduct {
  margin: 20px auto;
}

.cartProductInfo {
  font-size: 14px;
  padding-top: 10px;
}

.col-center {
  text-align: center;
}

.col-center-Info {
  text-align: center;
  padding: 100px 0;
}

.freeShipping {
  font-size: 14px;
}

.cartProductHr {
  border-bottom: 2px solid #ececed;
}

.cartSummary {
  margin: 0 20px;
}

.cartProductInfoBox {
  border-right: 2px solid #5e5e5e;
}

.cartSummaryHeading {
  color: #482611;
  font-size: 26px;
  padding-top: 20px;
}

.cartSummarySpan {
  text-align: end;
}

.trashBtn:hover {
  color: #ffc107;
}

.card-empty-cart {
  display: flex;
  /* margin : 50px 0; */
  align-items: center;
}

.card-empty-image {
  margin-right: 30px;
}

.cartSignin {
  margin-right: 15px;
}

.itemQuantityCount {
  display: inline-block;
  margin: 0 10px;
  padding: 0 15px;
  border: 1px solid black;
}

.cartListIndex {
  background-color: #f9f9f9;
  margin: 0;
  height: 30px;
  text-align: center;
  position: relative;
}

.cartListIndex div {
    top: 20%;
}
