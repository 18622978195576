.ratings-block {
  display: block;
}

.mb-20 {
  margin-bottom: 20px;
}

.input-label {
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 5px;
  font-family: "Cabin";
  color: #111111;
  margin-top: 0 !important;
}

.error,
.required-field {
  color: red;
  margin: 0 !important;
}
