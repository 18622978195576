.input-label {
  font-size: 13px !important;
  font-weight: 500;
  padding-bottom: 5px;
  font-family: "Cabin";
  color: #111111;
  margin-top: 15px;
}

.error,
.required-field {
  color: red;
  margin: 0 !important;
}

.signInInput {
  font-size: 13px !important;
  color: #111111 !important;
  padding-left: 10px;
  font-weight: 700 !important;
}

.forgot-pass {
  font-size: 13px !important;
  display: flex;
  align-items: center;
  font-weight: 700 !important;
}

.continueBtn {
  margin-top: 0;
}