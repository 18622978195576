.input-label {
  font-size: 13px !important;
  font-weight: 500;
  padding-bottom: 5px;
  font-family: "Cabin";
  color: #111111;
  margin-top: 15px;
}

.error,
.required-field {
  color: red;
  margin: 0 !important;
}

.p-text {
  font-size: 13px !important;
}

.create-acc-link {
  color: #1890ff !important;
  font-size: 13px;
  font-weight: bold;
}
.needHelp {
  color: #0066c0 !important;
  font-size: 13px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ant-form-item-explain-error {
  text-align: left !important;
}