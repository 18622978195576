.popup-checkbox {
    margin: 0 !important;
    font-weight: 400;
    font-size: 12px;
}

.input-label {
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 5px;
    font-family: "Cabin";
    color: #111111;
    margin-top: 15px;
}

.error,
.required-field {
    color: red;
    margin: 0 !important;
}

.buyBulkLabel {
    text-align: left;
}

.checkbox-buybulk {
    text-align: left;
}

.scrollable {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 130px;
}