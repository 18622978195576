/* .arrow-left {
  margin-right: 30px;
}

.arrow-right {
  margin-left: 30px;
} */

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.top-10 {
  top: 10px;
}

.featured-products-text {
  font-size: 32px;
  font-weight: 600;
}

@media only screen and (max-width: 319px) {
  .itemSection {
    flex-direction: column;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .itemSection {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 24px 0;
    align-items: flex-start;
  }

  .itemBlock {
    width: 75%;
  }

  .chakra-stack {
    padding: 0 3px !important;
  }

  .fa-xs {
    font-size: 0.6em;
  }

  .featured-products-text {
    font-size: 24px;
    position: relative;
    top: 25px;
    font-weight: 600;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .itemBlock {
    width: 50%;
  }
}