.address-buttons {
    width: 100%;
    padding: 0;
}

.address-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.addressFullName {
    font-size: 16px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.address-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
    height: 3em;
}

.fields-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
}

.absoluteLabel{
    position: absolute;
    left: 0;
}

.phone-overflow {
    position: relative;
    padding-left: 50px;
}

.country-overflow {
    position: relative;
    padding-left: 60px;
}

.state-overflow {
    position: relative;
    padding-left: 41px;
}

.city-overflow {
    position: relative;
    padding-left: 35px;
}

.postalCode-overflow {
    position: relative;
    padding-left: 80px;
}

.locationType-overflow {
    position: relative;
    padding-left: 95px;
}