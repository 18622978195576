.headerContainer {
    padding: 0 !important;
}

.alignleft {
    height: 85px;
}

.search-container {
    margin-top: 5%;
}

.headerContainer {
    padding: 0 20px !important;
}

.shop-dropdown .ant-dropdown-menu-submenu-title {
    font-size: 13px !important;
    line-height: 12px !important;
}

.shop-dropdown .ant-dropdown-menu-title-content {
    font-size: 13px !important;
    line-height: 12px !important;
}

.shop-dropdown .ant-dropdown-menu-submenu-expand-icon {
    bottom: 5px;
}

.headerEmai {
    color: #000000A6 !important;
}

ul.headerLinks li .f-icon span a:hover {
    text-decoration: underline;
}
ul.headerLinks li .f-icon:hover {
    text-decoration: underline;
}

/* Style to create scroll bar in dropdown */

.scrollable-dropdown {
    height: auto;
    cursor: pointer;
}

.scrollable-dropdown li a {
    margin: 10px;
    font-size: 13px !important;
}

.scrollable-dropdown li {
    line-height: 22px;
}


/* .scrollable-dropdown li a:hover {
  background-color: rgb(240, 230, 230);
} */

.dropdown-menu.show {
    backdrop-filter: blur(5px);
    max-height: 365px;
    margin-bottom: 10px;
    overflow-y: scroll;
}

.menu-text {
    display: block;
    font-size: 13px;
    color: #444;
    font-weight: 400;
    white-space: normal;
    word-wrap: break-word;
    margin-bottom: 5px;
}


/*  style to hidden the user name  */

.userName {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px;
    line-height: 15px;
}

.headerDropDownImg {
    height: 100%;
    margin-top: 6px;
    padding-left: 5px;
}

.phone-css,
.phone-inline {
    display: flex;
    align-items: center;
}

@media all and (max-width: 1198px) {
    .userName {
        max-width: 60px;
    }
}

@media all and (max-width: 992px) {
    .userName {
        max-width: 57px;
    }
    .right-items {
        padding-top: 15px !important;
    }
}

@media all and (min-width: 992px) {
    .dropdown-menu li {
        position: relative;
    }
    .dropdown-menu .submenu {
        display: none;
        position: absolute;
        left: 100%;
        top: -7px;
    }
    .dropdown-menu .submenu-left {
        right: 100%;
        left: auto;
    }
    .dropdown-menu>li:hover {
        background-color: #ffdf95;
    }
    .dropdown-menu>li:hover>.submenu {
        display: block;
    }
}

ul.navbar-nav li.nav-item .nav-link {
    font-size: 16px;
    color: #fff;
    margin: 15px 30px 0 0 !important;
    padding: 0 0 10px 0!important;
    font-weight: 400;
    letter-spacing: 0.5px;
    z-index: 2;
    position: relative;
}

ul.navbar-nav li.nav-item .nav-link:hover {
    color: #964b00;
}

.nav-link.active {
    border-bottom: 2px solid #633200;
}

.navbar-light .navbar-nav .nav-link.active {
    color: white;
}

.ant-dropdown-menu-submenu-popup ul {
    margin-left: -0.2em;
}

.ant-dropdown-menu {
    padding: 0;
    margin:-4px; 
}

.user-logo {
    color: white;
    font-size: 30px;
    margin-top: 6px;
}

.signin-dropdown-form {
    padding: 10px 0 0 0;
}
.cursor-pointer{
    cursor: pointer;
}

.navbar-custom {
    padding: 0px;
}

.badge-left {
    display: none;
}

.right-items {
    padding-top: 17px;
}

.userTxt {
    color: white;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer
}

.badge-right.mobile-screen {
    display: none;
}

.badge-right {
    cursor: pointer
}

.drawer-item {
    display: inline-flex;
    align-items: center;
    width: 100%;
    flex: 1;
    margin-bottom: 10px;
    cursor: pointer;
    justify-content: space-between;
}

.drawer-icon-flex {
    flex: 0.1;
}

.drawer-item-text-flex {
    flex: 0.85;
    margin: 0;
}

.fas.fa-bars.user-logo {
    display: none;
}

.header-left {
    margin: auto;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.ant-menu-submenu-title {
    border: none;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .linkText {
        margin: 5px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav {
        flex-direction: row;
    }
    .header-right-section {
        display: inline-flex;
    }
    .far.fa-user-circle.user-logo {
        display: none;
    }
    .linkText {
        margin: 5px 10px !important;
    }
    .dropdown-content {
        right: -100px;
    }
    /* .right-items {
        padding-top: 10px !important;
    } */
}

@media screen and (max-width: 767px) {
    .midRow {
        padding: 15px 0px 5px 0px;
    }
    .alignleft {
        height: 70px;
    }
    .headerContainer {
        padding: 10px 0px !important;
    }
    .fas.fa-bars.user-logo {
        display: flex;
    }
    .middle-search-area {
        width: 100%;
    }
    .search-panel {
        display: none;
    }
    .navbar-nav {
        flex-direction: row;
    }
    .badge-right.mobile-screen {
        display: flex;
    }
    .right-items {
        display: none;
    }
    #search {
        border-radius: 5px 0 0 5px;
    }
    .drawer-item a {
        color: black;
    }
   
    .nav-link.dropdown-toggle {
        padding: 0;
    }
    .drawer-categories-ul {
        margin: 0;
    }
    .drawer-categories-li {
        padding-bottom: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
    }
    .ant-drawer-body {
        padding-right: 0;
    }
    div.ant-menu-submenu-title {
        margin: 0 !important;
        padding-left: 0 !important;
    }
    .ant-menu-sub.ant-menu-inline>.ant-menu-item,
    .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
        padding-left: 24px !important;
    }
    .navbar-expand-lg.navbar-custom {
        display: none;
    }
}

@media screen and (max-width: 360px) {
    .header {
        display: inline-block;
    }
    ul.navbar-nav li.nav-item .nav-link {
        margin: 0 15px 0 0 !important;
    }
}

@media screen and (max-width: 1200px) {
    .headerContainer {
        padding: 0 10px !important;
    }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: none;
}
.ant-menu-submenu-title .ant-menu-title-content{
    font-weight: 600;
    font-size: 16px !important;
}
.sidebarMenu .ant-drawer-content-wrapper { top:146px;}
.sidebarMenu .ant-drawer-mask { background-color: rgba(0, 0, 0, 0.001); }
.sidebarMenu .ant-drawer-content { height: auto}
.ant-drawer-left>.ant-drawer-content-wrapper{ background:#FFF}


.ant-menu-sub .ant-menu-title-content {
    font-weight: 400;
    font-size: 14px !important;
}
