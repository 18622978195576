.changePasswordContainer {
    max-width: 30%;
    margin: 30px auto;
    background-color: #eee;
}

@media only screen and (min-width: 768px) and (max-width: 1600px) {
    .changePasswordContainer {
        max-width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .changePasswordContainer {
        max-width: 70%;
    }
}