.rating-stars {
    background: #fffee48c;
    padding: 0 10px;
}

.rating-container {
    display: inline-flex;
    align-items: center;
}

.ant-card {
    padding: 15px;
    border-radius: 4px!important;
}

.ant-card-cover {
    border: 1px solid #f4f4f4;
    border-radius: 0px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 240px;
    max-height: 240px;
}

.f-product {
    max-width: 100%;
    max-height: 220px;
}

.ant-card-body {
    padding: 1px 0;
}

.itemBlock .ant-card-meta-description {
    color: #f04f36;
    font-weight: 600;
}

.itemBlock .ant-card-meta-title {
    font-size: 18px;
    font-weight: 600;
}

.item-name {
    font-size: 14px;
    min-height: 45px;
    margin-bottom: 0 !important;
}

.item-price {
    color: #f04F36;
    font-weight: bold;
    font-size: 18px;
}

.featuredProductPrice {
    font-size: 18px !important;
}

@media only screen and (max-width: 319px) {
    .itemBlock {
        /* width: 100%; */
    }
    .dealHeading {
        font-size: 20px;
    }
    .featured-products-text {
        font-size: 24px;
    }
}

@media only screen and (max-width: 992px) {}

@media only screen and (min-width: 993px) and (max-width: 1200px) {}