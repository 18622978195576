.thumb-images:hover {
    cursor: pointer;
    box-shadow: #ddd 0px 0px 8px 2px;
}
.primary a{
    color: blue !important;
}

li>input.thumb-eg {
    border-radius: 6px;
}

.mt-10 {
    margin-top: 10px;
}

.qty-size-container {
    display: flex;
    flex: 1;
}

.flex-half {
    flex: 0.3;
}

.color-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 35%;
}

.color-variant {
    max-width: 30px;
    height: 30px;
    flex: 1;
    transition: all 0.2s ease-in-out;
}

.color-variant:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.main-img-container {
    position: sticky;
    /* margin: 20px 0; */
    top: 97;
}

.detailPriceStock {
    margin: 0;
}

.detailPriceStock>div {
    padding: 0;
}

.detail-itemListName {
    font-weight: 600;
}

.rating-container {
    margin: 10px 0;
    width: 100%;
    justify-content: space-between;
}

.thumb-images {
    margin-bottom: 15px;
    flex: 1;
    border: 1px solid #a2a6ac;
    border-radius: 2px;
    padding: 5px
}

.ant-btn-primary {
    border-color: #964b00 !important;
    background: #964b00 !important;
    border-radius: 0.25rem;
    height: auto;
}

.ant-btn-primary:hover {
    border-color: #633200 !important;
    background: #633200 !important;
}

.ant-btn-secondary:hover {
    background-color: #633200 !important;
    border-color: #633200 !important;
    color: #fff !important;
}

.ant-btn-lg {
    width: 100%;
}

.details-page-content {
    justify-content: space-around;
    /* margin: 0 10px 20px 10px; */
}

.sticky {
    position: sticky;
    top: 65px;
}

.breadCrumb-sticky {
    position: sticky;
    top: 0;
    z-index: 2;
}

.thumb-imgs {
    display: flex;
    flex: 1;
    justify-content: space-between;
    /* margin: 0 10px 20px 10px; */
    flex-direction: column;
}

.detail-itemListCaption>ul>li {
    list-style: disc;
}


/* .rating-container {
  margin: 10px 0;
  box-shadow: 0 0 11px #4AB4EE;
  border-radius: 10px;
}

.margin-lr-15 {
  margin: 0 15px;
}

.margin-tb-20 {
  margin: 20px 0;
}

.ps-sticky {
  position: sticky;
}

.top-10 {
  top: 10;
} */

.mainImageDiv>div>div.ant-image {
    width: 100%;
}
.mainImageDiv .productImageDiv .ant-image {justify-content: center;  display: flex;}
.mainImageDiv .productImageDiv .ant-image-img { max-width: 100%; max-height: 480px; width:auto;padding: 24px;}

.ant-popover-title {
    color: #964B00;
    font-size: medium;
    font-weight: 600;
}
.form-select{
    -webkit-appearance: auto !important;
}
.ant-popover-inner {
    /* border-radius: 10px !important; */
    box-shadow: 0 0 6px 4px rgb(33 48 56 / 20%);
    border: 1px solid #ddd;
    /* padding: 12px; */
}

.productDetailContainer {
    max-width: 90%;
}

.ant-modal-confirm-btns .ant-btn span {
    padding: 0 15px !important;
}

.productDetailThumb {
    text-align: center;
}

.productDetailThumbImg {
    height: 50px;
}

.disableBtnColor {
    color: rgba(0, 0, 0, 0.85) !important;
}

@media only screen and (max-width: 540px) {
    .productDetailThumb {
        text-align: center;
    }
    .productDetailThumbImg {
        height: 30px !important;
    }
    
}

@media only screen and (max-width: 1600px) {
    .productDetailThumbImg {
        height: 40px;
    }

}

@media only screen and (max-width: 1200px) {
    .productDetailThumb {
        width: 80%;
    }
    .thumb-images {
        padding: 1px;
    }
    .sticky {
        position: inherit;
    }
}

@media only screen and (max-width: 768px) {
    .ant-btn-lg {
        margin-bottom: 10px;
    }
    .productDetailThumb {
        width: 100%;
    }
}