        .col1 {
            flex: 0.75;
        }
        
        .a-cardui {
            margin: 20px 0;
            background-color: #fff;
            overflow: auto;
            padding: 20px 0 15px 0;
        }
        
        .col2 {
            flex: 0.23;
            max-height: 200px;
            position: sticky;
            top: 20px;
        }
        
        .a-cardui .a-cardui-body:last-child {
            padding-bottom: 5px;
        }
        
        .a-cardui .a-cardui-body {
            padding: 0 20px;
            position: relative;
        }
        
        * {
            box-sizing: border-box;
        }
        
        .a-spacing-none,
        .a-ws,
        .a-ws-spacing-none {
            margin-bottom: 0 !important;
        }
        
        .a-row {
            width: 100%;
        }
        
        .vat-title {
            width: 100%;
            display: block;
            font-size: 14px !important;
            color: gray !important;
            margin: 0;
        }
        
        .sc-cart-header.sc-compact-bottom {
            margin-bottom: -10px;
        }
        
        .a-ws,
        div.a-column,
        div.a-column {
            margin-right: 2%;
            float: left;
            min-height: 1px;
            overflow: visible;
        }
        
        .a-row .a-span10,
        .a-ws,
        .a-row .a-ws-span10 {
            width: 82.948%;
        }
        
        .a-ws .a-ws-span12,
        div.a-column.a-span-last {
            margin-right: 0;
            float: right;
        }
        
        .sc-list,
        .sc-list-head {
            border-bottom: 1px solid rgb(212, 203, 203);
        }
        
        .a-row:after,
        .a-row:before {
            display: table;
            content: "";
            line-height: 0;
            font-size: 0;
        }
        
        .a-row:after {
            clear: both;
        }
        
        .a-row:after,
        .a-row:before {
            display: table;
            content: "";
            line-height: 0;
            font-size: 0;
        }
        
        .a-spacing-top-base {
            margin-top: 12px !important;
        }
        
        .a-spacing-base,
        .a-ws .a-ws-spacing-base {
            margin-bottom: 12px !important;
        }
        
        .a-row:after,
        .a-row:before {
            display: table;
            content: "";
            line-height: 0;
            font-size: 0;
        }
        
        .a-ws div.a-column,
        div.a-column {
            margin-right: 2%;
            float: left;
            min-height: 1px;
            overflow: visible;
        }
        
        .a-ws .a-ws-span12,
        div.a-column.a-span-last {
            margin-right: 0;
            float: right;
        }
        
        .a-ws div.a-column,
        div.a-column {
            margin-right: 2%;
            float: left;
            min-height: 1px;
            overflow: visible;
        }
        
        .a-row .a-span2,
        .a-ws .a-row .a-ws-span2 {
            width: 14.948%;
        }
        
        .a-row:after,
        .a-row:before {
            display: table;
            content: "";
            line-height: 0;
            font-size: 0;
        }
        
        .a-row:after {
            clear: both;
        }
        
        .a-fixed-left-grid-inner:after,
        .a-fixed-left-grid-inner:before,
        .a-fixed-right-grid-inner:after,
        .a-fixed-right-grid-inner:before {
            display: table;
            content: "";
            line-height: 0;
            font-size: 0;
        }
        
        .a-fixed-left-grid-inner:after,
        .a-fixed-left-grid-inner:before,
        .a-fixed-right-grid-inner:after,
        .a-fixed-right-grid-inner:before {
            display: table;
            content: "";
            line-height: 0;
            font-size: 0;
        }
        
        .a-fixed-left-grid-inner:after,
        .a-fixed-right-grid-inner:after {
            clear: both;
        }
        
        .a-fixed-right-flipped-grid .a-col-left {
            overflow: hidden;
            zoom: 1;
            width: auto;
        }
        
        .a-float-left,
        .aok-float-left {
            float: left !important;
        }
        
        .a-fixed-left-grid-col,
        .a-fixed-right-grid-col {
            position: relative;
            overflow: visible;
            zoom: 1;
            min-height: 1px;
        }
        
        a,
        a:link,
        a:visited {
            text-decoration: none;
            color: #007185;
        }
        
        .a-fixed-left-grid .a-col-right {
            width: 100%;
            padding-left: 3.5%;
        }
        
        .a-ordered-list.a-nostyle,
        .a-unordered-list.a-nostyle,
        ol.a-nostyle,
        ul.a-nostyle {
            margin-left: 0;
            color: #0f1111;
        }
        
        .a-ordered-list,
        .a-unordered-list,
        ol,
        ul {
            padding: 0;
        }
        
        .a-unordered-list,
        ul {
            color: #0f1111;
        }
        
        .a-spacing-mini,
        .a-ws .a-ws-spacing-mini {
            margin-bottom: 4px !important;
        }
        
        .a-ordered-list .a-list-item,
        .a-unordered-list .a-list-item,
        ol .a-list-item,
        ul .a-list-item {
            color: #0f1111;
        }
        
        a,
        a:link,
        a:visited {
            text-decoration: none;
            /* color       : #007185; */
            color: black;
        }
        
        .a-spacing-mini,
        .a-ws .a-ws-spacing-mini {
            margin-bottom: 4px !important;
        }
        
        .sc-white-space-nowrap {
            white-space: nowrap;
        }
        
        .sc-white-space-nowrap {
            white-space: nowrap;
        }
        
        .a-color-base {
            color: #0f1111 !important;
        }
        
        .a-size-medium {
            text-rendering: optimizeLegibility;
            font-size: 20px !important;
        }
        
        .a-size-delivery {
            font-size: 16px !important;
        }
        
        .a-text-bold {
            font-weight: 700 !important;
        }
        
        .currencyINR {
            font-size: 16px !important;
            font-weight: bold;
        }
        
        .currencyINR:before {
            content: "\00A3";
        }
        /* .currencyINR:after {
  content: ".00";
} */
        
        .currencyINR-desc-area:before {
            content: "\00A3";
        }
        /* .currencyINR-desc-area:after {
  content: ".00";
} */
        
        .sc-list,
        .sc-list-item-border {
            border-bottom: 1px solid #ddd;
        }
        
        .sc-product-image {
            height: 200px;
            width: 100%;
        }
        
        .sc-list,
        .sc-list-item,
        .sc-action-links {
            margin-top: -4px;
            margin-bottom: 3px;
            grid-area: 5/1/-1/-1;
        }
        
        .a-row:after,
        .a-row:before {
            display: table;
            content: "";
            line-height: 0;
            font-size: 0;
        }
        
        .a-row:after {
            clear: both;
        }
        
        .a-row:after,
        .a-row:before {
            display: table;
            content: "";
            line-height: 0;
            font-size: 0;
        }
        
        .a-icon-text-separator,
        .a-text-separator {
            display: inline-block;
            margin: -2px 0.67375em 0;
            width: 1px;
            background-color: #ddd;
            line-height: 0;
            height: 14px;
            vertical-align: middle;
        }
        
        .delete-loading {
            min-height: 200px;
            display: grid;
            justify-content: space-around;
            align-content: space-around;
        }
        
        .updating-cart {
            opacity: 0.3;
        }
        
        .a-color-success {
            color: #007600 !important;
        }
        
        .a-color-error {
            color: #c21e1e !important;
        }
        
        .overflow-hidden {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        
        .cart-image {
            width: 190px;
            margin-left: -190px;
            float: left;
            border-right: 1px solid #ddd;
        }
        
        .cart-item-container {
            display: flex;
            flex: 1;
            justify-content: space-between;
        }
        
        .cart-image-area {
            flex: 0.2;
            border-right: 1px solid #ddd;
        }
        
        .cart-desc-area {
            flex: 0.65;
            position: relative;
        }
        
        .desc-title {
            position: absolute;
            top: 0;
        }
        
        .desc-links {
            position: absolute;
            bottom: 0;
        }
        
        .desc-stock {
            position: absolute;
            top: 30%;
        }
        
        .cart-price-area {
            flex: 0.12;
        }
        
        .cart-price-header {
            display: flex;
            flex: 1;
            justify-content: space-between;
            border-bottom: 1px solid #ddd;
        }
        
        .cart-price-header-free-space {
            flex: 0.85;
        }
        
        .cart-price-header-text {
            text-align: right;
            font-weight: 600;
            color: rgb(107, 107, 107);
        }
        
        .desc-stock-pp-container {
            display: none;
        }
        
        .proceed-button-text {
            margin: 0;
        }
        
        .qty-container {
            border: 1px solid #f3f0f0;
            padding: 0 5px;
        }
        
        .shopping-cart-text {
            font-size: 28px;
            font-family: "Cabin";
            font-weight: 700;
        }
        
        .currencyINR-desc-area {
            display: none;
        }
        
        .ptb-10 {
            padding: 10px 0;
        }
        
        .quantity-input-simple {
            display: none;
        }
        
        .newCurrency:before {
            content: "\00A3";
        }
        
        .delete-button {
            color: #d11a2a
        }
        
        .delete-button:hover {
            border-color: #d11a2a;
            box-shadow: 0 0 5px #d11a2a;
        }
        
        .cart-short-description {
            font-size: 16px !important;
            position: absolute;
            top: 45%;
            width: 100%;
        }
        /* Responsive */
        
        @media only screen and (min-width: 1800px) {
            .sc-product-image {
                height: 250px;
            }
            .a-size-medium {
                font-size: 24px !important;
                /* line-height: 30px !important; */
            }
            .a-size-delivery {
                font-size: 20px !important;
            }
            .desc-stock {
                font-size: 18px;
            }
            .currencyINR {
                font-size: 24px;
            }
        }
        
        @media only screen and (max-width: 1400px) {
            .cart-short-description {
                font-size: 14px !important;
            }
            .sc-product-image {
                height: 200px;
            }
            .desc-stock {
                top: 28%;
            }
            .a-size-medium {
                font-size: 16px !important;
                /* line-height: 20px !important; */
            }
            .a-size-delivery {
                font-size: 12px !important;
            }
            .currencyINR {
                font-size: 18px;
            }
            .proceed-button-text {
                font-size: 14px;
            }
        }
        
        @media only screen and (max-width: 1100px) {
            .cart-short-description {
                display: none;
            }
            .sc-product-image {
                height: 150px;
            }
            .desc-stock {
                top: 40%;
            }
        }
        
        @media only screen and (max-width: 767px) {
            .cart-short-description {
                display: none;
            }
            .a-size-medium {
                font-size: 12px !important;
                line-height: 18px;
            }
            .a-size-delivery {
                font-size: 10px !important;
            }
            .sc-product-image {
                height: 100px;
            }
            .proceed-button-text {
                font-size: 14px;
            }
            .desc-stock {
                top: 28%;
            }
            .desc-links {
                font-size: 12px;
            }
            .cart-price-area>.currencyINR,
            .subtotal-inside-cart-items,
            .cart-price-area,
            .chakra-rating-container-rate,
            .cart-price-header {
                display: none;
            }
            .cart-desc-area {
                flex: 0.78;
            }
            .currencyINR-desc-area {
                display: flex;
                font-size: 13px;
                position: absolute;
                top: 45px;
                font-weight: bold;
            }
            .currencyINR-desc-area::before {
                content: "";
            }
            .sc-cart-header {
                border-bottom: 1px solid #ddd;
                margin-bottom: 4px !important;
                padding-bottom: 5px !important;
            }
            .quantity-inc-desc {
                display: none;
            }
            .quantity-input-simple {
                display: inline-block;
                width: 50px;
                border: 1px solid #ddd;
                height: 20px;
                padding: 5px;
            }
        }
        
        @media only screen and (max-width: 600px) {
            .sc-product-image {
                height: 85px;
            }
            .lh-settings {
                line-height: 16px;
            }
            .currencyINR {
                font-size: 18px;
                font-weight: bold;
            }
            .proceed-button-text {
                font-size: 12px;
            }
            .desc-stock {
                top: 15%;
            }
            .overflow-hidden {
                font-size: 16px;
            }
            .sc-product-availability {
                font-size: 12px;
            }
            .shopping-cart-text {
                font-size: 18px;
            }
        }
        
        @media only screen and (min-width: 481px) and (max-width: 600px) {
            .sc-product-image {
                height: 100px;
            }
            .desc-stock {
                top: 26%;
            }
        }
        
        @media only screen and (min-width: 320px) and (max-width: 480px) {
            .overflow-hidden {
                font-size: 12px;
            }
            .sc-product-image {
                height: 80px;
            }
            .cart-price-area {
                display: none;
            }
            .chakra-rating-container-rate {
                display: none;
            }
            .cart-desc-area {
                flex: 0.78;
            }
            /* .cart-price-area {
    flex: 0.18;
    position: relative;
  } */
            .cart-price-header-free-space {
                flex: 0.8;
            }
            .cart-price-header-text {
                flex: 0.18;
            }
            .desc-stock-pp-container {
                display: flex;
                font-size: 12px;
            }
            .a-cardui .a-cardui-body {
                padding: 0 10px;
            }
            .shopping-cart-text {
                font-size: 20px;
            }
            .a-size-medium {
                font-size: 12px !important;
                line-height: 10px !important;
            }
            .a-size-delivery {
                font-size: 10px !important;
            }
            .desc-links {
                font-size: 10px;
            }
            .desc-stock {
                font-size: 10px;
                top: 28px;
            }
            .currencyINR-desc-area {
                display: flex;
                font-size: 10px;
                position: absolute;
                top: 41px;
            }
            /* .cart-price-area > .currencyINR,
  .subtotal-inside-cart-items,
  .cart-price-area,
  .chakra-rating-container-rate,
  .cart-price-header {
    display: none;
  }

  .sc-cart-header {
    border-bottom: 1px solid #ddd;
    margin-bottom: 4px !important;
    padding-bottom: 5px !important;
  } */
        }
        
        @media only screen and (max-width: 279px) {
            .sc-product-image {
                height: 55px;
            }
            .a-size-medium>span>span {
                font-size: 9px;
                line-height: 11px;
            }
            .desc-stock {
                top: 53%;
                font-size: 8px;
            }
            .desc-links {
                width: 100%;
                font-size: 8px;
            }
            .currencyINR {
                font-size: 10px !important;
            }
            .chakra-rating-container-rate {
                display: none;
            }
            .ant-select-selection {
                background-color: green;
            }
            .currencyINR {
                font-size: 8px;
            }
            .lh-settings {
                line-height: 12px;
            }
            .space-desc-links {
                gap: 0 !important;
            }
            .proceed-button-text {
                font-size: 10px;
                position: absolute;
            }
            .detailBuy {
                font-size: 70%;
            }
            .cart-price-header-text {
                font-size: 10px;
            }
            .shopping-cart-text {
                font-size: 18px;
            }
            .a-cardui {
                margin-top: 0;
                padding: 8px 0;
            }
            .currencyINR-desc-area {
                font-size: 8px;
                top: 39%;
            }
            .quantity-input-simple {
                display: inline-block;
                width: 30px;
                border: 1px solid #ddd;
                height: 13px;
                padding: 5px;
            }
        }
        
        @media only screen and (max-width: 764px) {
            .cartInput input.ant-input-number-input {
                height: 70%;
                font-size: 12px;
            }
        }
        
        @media only screen and (max-width: 319px) {
            .overflow-hidden {
                font-size: 12px;
            }
            .desc-stock {
                top: 26%;
            }
            .currencyINR-desc-area {
                top: 38px;
                font-size: 12px;
            }
        }