.contactUsContainer {
    max-width: 40%;
}

.contact-form-label .ant-form-item-required {
    width: 105px;
}

.contact-us-card {
    /* box-shadow: 0 4px 14px 0 rgb(0 0 0 / 14%); */
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 14%);
    padding   : 20px 20px;
}

.contact-us-title {
    text-align: center;
}

.pageInfo {
    font-size: 14px !important;
    line-height: 23px;
}


@media only screen and (max-width: 1600px) {
    .contactUsContainer {
        max-width: 50%;
    }
}

@media only screen and (max-width: 1024px) {
    .contactUsContainer {
        max-width: 70%;
    }
}

@media only screen and (max-width: 767px) {
    .contactUsContainer {
        max-width: 90%;
    }
}