.policyContainer {
    box-shadow: 0 4px 14px 0 rgb(0 0 0 / 14%);
    padding: 20px 40px;
}

.para-info {
    font-size: 18px !important;
    line-height: 27px;
}

.para-title {
    font-size: 20px !important;
    line-height: 27px;
}