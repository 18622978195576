.errorPageContainer {
  width: 50%;
}
.errorPageLogo {
  text-align: center;
  padding-top: 50px;
}
.errorPageInfo {
  padding-top: 50px;
}
.errorPageOops {
  padding-top: 35px;
  font-size: 60px;
}
.errorPageCantFind {
  font-size: 26px;
  color: #585858;
}
.errorPageError {
  font-size: 20px;
  padding: 20px 0;
}
.errorPageGoLink {
  font-size: 20px;
  color: #585858;
  font-weight: bold;
}
.errorPageImg {
  text-align: center;
}
.errorPageHome {
  color: #b37312;
}
.errorPageHome:hover {
  color: #b37312;
}

@media screen and (max-width: 1400px) {
  .errorPageContainer {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .errorPageOops {
    font-size: 50px;
  }
  .errorPageCantFind {
    font-size: 20px;
  }
  .errorPageError {
    font-size: 18px;
    padding: 10px 0;
  }
}

@media screen and (max-width: 768px) {
  .errorPageInfo {
    text-align: center;
  }
  .errorPageCantFind {
    font-size: 22px;
  }
  .errorPageInfo {
    padding-top: 0px;
  }
  .errorPageOops {
    font-size: 40px;
  }
  .errorPageGoLink {
    font-size: 18px;
  }
}
