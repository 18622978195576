.homeAdsContainer {
    max-width: 90% !important;
    margin-top: 10px;
}

.sectionAds .col-12 {
    margin-bottom: 30px;
}

.homeCategoryContainer .col-12 {
    margin-bottom: 0px;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
}

.homeCategoryContainer {
    display: flex;
    padding: 30px 20px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    box-shadow: 0 2px 9px #f0f0f0;
    height: 100%;
}

.pageContent {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 0 0 30px 0;
}

.grid-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(9, 1fr);
    grid-gap: 30px;
}

.grid-one {
    grid-area: 1/1/4/3;
}

.grid-two {
    grid-area: 4/1/7/3;
}

.grid-three {
    grid-area: 1/3/3/4;
}

.grid-four {
    grid-area: 3/3/5/4;
}

.grid-five {
    grid-area: 5/3/7/4;
}

.deal-of-the-day {
    grid-area: 7/1/-1/-1;
}

.adsImage {
    width: 100%;
}

.featured-categ-desc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.grid-child {
    width: 50%;
    float: left;
    padding: 0 15px;
    /* border: 2px solid red; */
}

.grid-item-large {
    display: flex;
    padding: 40px 10px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    box-shadow: 0px 2px 9px #F0F0F0
}

.grid-item-deal {
    display: flex;
    padding: 30px;
    /* align-items: center;
  justify-content: center; */
    background-color: #fff;
    flex: 1;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    box-shadow: 0px 2px 9px #F0F0F0
}
.grid-item-deal h3.ant-typography { text-transform: uppercase;}

.grid-container p {
    margin: 0 0 10px;
    font-family: "Cabin", sans-serif;
}

.grid-item-small {
    display: flex;
    padding: 20px 10px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    box-shadow: 0px 2px 9px #F0F0F0
}

.grid-item-small p {
    font-size: 13px;
}

.settings {
    width: 50%;
    padding-left: 15px;
}
/*
.timeBlock {
    background-color: #332d34;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    padding: 12px 6px;
}*/
.timeBlock {
    background-color:transparent;
    border-radius: 8px;
    color: rgb(15 17 17 / 60%);
    text-align: center;
    padding: 12px 6px;
    border:1px solid rgb(150 75 0 / 50%);; 
}
.timingHead { color:#964b00; font-weight: 700; font-size: 20px;}
.dealInfo {
    background-color: #f9f9f9;
}

.deal-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; 
    margin-bottom: 1.5rem; ;
}

@media only screen and (max-width: 320px)  {
    .brandBtn {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .grid-container {
        grid-template-rows: repeat(5, 1fr) !important;
    }
    .pageContent {
        padding: 0 !important;
    }
    .grid-container {
        display: flex;
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-gap: 10px;
        height: auto;
        flex-wrap: wrap;
    }
    .grid-one {
        grid-area: 1/1/2/-1;
    }
    .grid-two {
        grid-area: 2/1/3/-1;
    }
    .grid-three {
        grid-area: 3/1/4/-1;
    }
    .grid-four {
        grid-area: 4/1/5/-1;
    }
    .grid-five {
        grid-area: 5/1/6/-1;
    }
    .deal-of-the-day {
        grid-area: 6/1/-1/-1;
    }
    .grid-item-large,
    .grid-item-small {
        display: flex;
        padding: 20px;
        flex-direction: column-reverse;
        justify-content: space-around;
    }
    .adsHeading {
        font-size: 16px;
    }
    .deal-of-the-day {
        display: flex;
    }
    .dealRow {
        flex-direction: column;
    }
    .grid-item-deal {
        padding: 20px;
    }
    .dealTiming {
        flex: 1;
        justify-content: space-around;
    }
    .dealInfo {
        padding: 20px;
    }
    .oldPrice {
        padding-left: 0;
    }
    .grid-item-small img.adsImage {
        float: right;
    }
    .grid-child {
        padding: 5px;
        width: 100%;
    }
    .grid-item-small {
        position: relative;
    }
    .grid-item-small>div:nth-child(2) {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .itemBlock {
        width: 25%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .dealInfo {
        width: 100%;
    }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
    .grid-child {
        width: 100%;
    }
    .grid-container {
        grid-template-rows: repeat(5, 1fr) !important;
    }
    .grid-one {
        grid-area: 1/1/2/-1;
    }
    .grid-two {
        grid-area: 2/1/3/-1;
    }
    .grid-three {
        grid-area: 3/1/4/-1;
    }
    .grid-four {
        grid-area: 4/1/5/-1;
    }
    .grid-five {
        grid-area: 5/1/6/-1;
    }
    .deal-of-the-day {
        grid-area: 6/1/8/-1;
    }
    .grid-item-small {
        justify-content: unset;
    }
    .grid-item-large>.grid-child:nth-child(2),
    .grid-item-small>.grid-child:nth-child(2),
    .grid-item-deal>.grid-child:nth-child(2) {
        margin: auto;
    }
    .adsHeading {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .grid-item-small {
        flex-direction: column;
        justify-content: space-around;
    }
    .settings {
        width: 100%;
    }
    .adsImage {
        margin-top: 15px;
    }
}



@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

@media only screen and (min-width: 1480px) {
    .container {
        max-width: 1440px;
    }
}

@media only screen and (min-width:280px) {
    .grid-container {
        justify-content: space-around;
    }
}

@media only screen and (max-width:576px) {
    .homeCategoryContainer img {
        margin-top: 15px;
    }
}