.info-container {
    border: 1px #d5d9d9 solid;
    border-radius: 8px;
    min-height: 100px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.info-container:hover {
    box-shadow: 6px 6px 2px 1px rgba(143, 143, 143, 0.2);
    padding: 12px;
    border: 2px #d5d9d9 solid;
}