.item-container {
    box-shadow: 0 4px 14px 0 rgb(0 0 0 / 14%);
}

.order-history-column {
    font-weight: bold;
}

.order-details-link {
    color: #1890FF !important;
}

.orderHistoryContainer {
    max-width: 60%;
    margin: 50px auto;
}

.orderIdColumn {
    padding-left: 30px !important;
}

.order-history td,
.order-history th {
    border: 0.5px solid #ddd;
    padding: 3px 6px;
}

.order-history th {
    white-space: nowrap;
}

.ta-c {
    text-align: center;
}

.red-color {
    color: #f04f36
}

.green-color {
    color: #008000
}

@media only screen and (max-device-width: 1240px) {
    .orderHistoryContainer {
        max-width: 90%;
    }
}