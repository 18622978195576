.aboutUsCard {
    box-shadow: 0 4px 14px 0 rgb(0 0 0 / 14%);
    padding: 20px 40px;
}

.aboutUsSubTitle {
    padding-top: 10px;
    line-height: 33px;
    font-weight: 700;
}

.para-info {
    font-size: 18px !important;
    line-height: 27px;
}
.container.aboutUsContainer{ padding-right: 15px !important; padding-left: 15px !important; }
.aboutUsCard{ max-width:1000px; margin: 40px auto; }

@media only screen and (max-width: 767px) {
.aboutUsCard h3.ant-typography{ text-align: center;}
.aboutUsCard{ padding:15px; margin:15px auto; }
.aboutUsCard .pageInfo {  font-size: 14px !important; line-height: 26px; }


}