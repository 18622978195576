.selectPaymentMethodContainer {
    width: 100%;
    margin: 50px auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 40px;
}

.selectPaymentMethodheading {
    color: #482611;
    font-size: 26px;
    padding: 30px 0;
    font-weight: bold;
}

.paymentInfo {
    font-size: 14px;
    color: #5d5d5d;
}

.paymentRowFirst,
.paymentRowSecond,
.paymentRowThird {
    padding: 40px 0;
}

.paymentSpanTitle {
    color: #482611;
    font-size: 14px;
    font-weight: bold;
}

.paymentCard {
    border: 3px dotted #000;
    width: 100%;
    height: 100%;
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.pay-with {
    font-style: italic;
    font-weight: 800;
    font-size: 16px;
}

@media only screen and (max-device-width: 768px) {
    .selectPaymentMethodContainer {
        padding: 30px 20px 30px 20px
    }
    .paymentTitle {
        font-size: 22px !important;
    }
}