.rating-contents {
    float: left;
}

.itemListContainer {
    background: #fff;
    border-radius: 6px;
    padding: 36px 15px;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
    text-align: left;
    align-items: flex-start;
    justify-content: center;
}

.prod-block-info-section {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.itemListPrice {
    padding: 0 !important;
}

.currencyINR {
    font-size: 24px;
}

.priceTitle {
    font-size: 20px;
    font-weight: bold;
    color: #964b00;
    cursor: pointer;
}

.subtotalText {
    font-size: 16px;
    padding-left: 10px;
    font-weight: bold;
    color: #964b00;
}

.ant-typography {
    font-size: 16px;
}

.ant-typography a {
    color: #1890ff !important;
    font-weight: 700 !important;
}

.itemListImg {
    height: 200px;
    max-width: 100%;
}

.ant-btn-primary {
    color: #fff !important;
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
    .itemListImg {
        height: 150px;
        max-width: 100%;
    }
}

@media only screen and (max-width: 1800px) {
    .priceTitle {
        font-size: 18px;
    }
}