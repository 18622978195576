.container.feedbackContainer {
    padding-right: 15px !important;
    padding-left: 15px !important;
    max-width: 50%;
}

.feedbackCard {
    padding: 20px 100px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 14%);
}

.feedback-form {
    padding-top: 30px
}