.pr-5 {
    position: absolute;
    left: 0px;
    top: 3px;
}

.addressList {
    position: relative;
    padding-left: 20px;
}
@media(max-width:767px){
.footer .footerContent .row{ width:auto; }
.footer .footerContent .col-md-3:nth-child(1){ text-align: center; }
.footer .socialSites{ text-align: center; display: inline-block; } 
}